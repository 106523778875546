import React from "react";

// Import Components
import Navbar from "../components/Navbar";
import Home from "../components/Home";
import About from "../components/About";
import Services from "../components/Services";
import Contact from "../components/Contact";
// import Maps from "../components/Maps";

function Homepage() {
  return (
    <div>
      <Navbar />
      <Home />
      <About />
      <Services />
      <Contact />
      {/* <Maps /> */}
    </div>
  );
}

export default Homepage;
