import React from "react";

import "../styles/About.css";
import AboutImg from "../assets/images/about001.jpg";

function About() {
  return (
    <div className="about-container" id="about">
      <div className="about-left">
        <img src={AboutImg} alt="About Us" />
      </div>
      <div className="about-right">
        <h1>Who We Are</h1>
        <p>
          At Azul & Bubbles, we understand the importance of a clean and healthy
          environment. That's why we offer a wide range of cleaning services
          tailored to meet the unique needs of our clients. Whether you need a
          one-time deep cleaning or a regular cleaning service, we've got you
          covered.
        </p>
        <p>
          Our team of professional cleaners is fully equipped with the latest
          cleaning tools and products to ensure the best possible results. We
          use eco-friendly and non-toxic cleaning solutions to ensure the safety
          of your family and pets.
        </p>
        <p>
          We pride ourselves on our attention to detail and our commitment to
          customer satisfaction. We guarantee that you'll be satisfied with our
          cleaning services, or we'll make it right.
        </p>

        <p>
          Thank you for considering Us for your cleaning needs. Contact us today
          to schedule a cleaning appointment or to learn more about our
          services.
        </p>
      </div>
    </div>
  );
}

export default About;
