import React from "react";

import "../styles/Services.css";

import Healthcare from "../assets/images/healthcareProperties.jpg";
import Hospitals from "../assets/images/hospitals.jpg";
import Restaurants from "../assets/images/restaurants.jpg";
import Schools from "../assets/images/schools.jpg";
import Warehouses from "../assets/images/warehouse.jpg";
import windowsCleaning from "../assets/images/windowsCleaning.jpg";
import Manufacturing from "../assets/images/manufactures.jpg";
import Hospitality from "../assets/images/hospitality.jpg";

function Services() {
  return (
    <div className="services-container">
      <div className="services-title" id="services">
        <h1>Services</h1>
      </div>
      <div className="services-content">
        <div className="services-card">
          <img src={Healthcare} alt="Service 01" />
          <h2>Healthcare Properties</h2>
          {/* <p>Lorem ipsum dolor sit amet consectetur adipisicing elit.</p> */}
        </div>
        <div className="services-card">
          <img src={Restaurants} alt="Service 02" />
          <h2>Restaurants</h2>
          {/* <p>Lorem ipsum dolor sit amet consectetur adipisicing elit.</p> */}
        </div>
        <div className="services-card">
          <img src={Hospitals} alt="Service 03" />
          <h2>Hospitals</h2>
          {/* <p>Lorem ipsum dolor sit amet consectetur adipisicing elit.</p> */}
        </div>
        <div className="services-card">
          <img src={Schools} alt="Service 04" />
          <h2>Schools and Daycares</h2>
          {/* <p>Lorem ipsum dolor sit amet consectetur adipisicing elit.</p> */}
        </div>
        <div className="services-card">
          <img src={Warehouses} alt="Service 05" />
          <h2>Warehouses</h2>
          {/* <p>Lorem ipsum dolor sit amet consectetur adipisicing elit.</p> */}
        </div>
        <div className="services-card">
          <img src={windowsCleaning} alt="Service 06" />
          <h2>Windows Cleaning</h2>
          {/* <p>Lorem ipsum dolor sit amet consectetur adipisicing elit.</p> */}
        </div>
        <div className="services-card">
          <img src={Manufacturing} alt="Service 07" />
          <h2>Manufacturing Facilities</h2>
          {/* <p>Lorem ipsum dolor sit amet consectetur adipisicing elit.</p> */}
        </div>
        <div className="services-card">
          <img src={Hospitality} alt="Service 08" />
          <h2>Hospitality Buildings</h2>
          {/* <p>Lorem ipsum dolor sit amet consectetur adipisicing elit.</p> */}
        </div>
      </div>
    </div>
  );
}

export default Services;
