import React from "react";

import "../styles/Navbar.css";
import styled from "styled-components";
import Logo from "../assets/images/logo001.png";

function Navbar({ navToggle }) {
  return (
    <StyledNavbar>
      <div className={`${navToggle ? "nav-toggle" : ""}`}>
        <div className="Navbar">
          <div className="navbar-links_left">
            <a href="#home">Home</a>
            <a href="#about">About</a>
          </div>
          <div className="navbar-logo">
            <img src={Logo} alt="AzulBubbles Logo" />
          </div>
          <div className="navbar-links_right">
            <a href="#services">Services</a>
            <a href="#contact">Contact</a>
          </div>
        </div>
      </div>
    </StyledNavbar>
  );
}

export default Navbar;

const StyledNavbar = styled.div`
  @media (max-width: 767px) {
    .Navbar {
      height: 80px;
      display: flex;
      justify-content: flex-start;
    }
    .navbar-logo {
      width: 100%;
      height: 80px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .navbar-logo img {
      height: 60px;
      width: auto;
      text-align: center;
      
    }
    .navbar-links_left, .navbar-links_right {
      display: none;
  }
`;
