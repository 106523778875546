import React from "react";

import "../styles/Home.css";
import Hero from "../assets/images/hero001.jpg";

function Home() {
  return (
    <div className="home-container" id="home">
      <div className="home-hero">
        <img src={Hero} alt="Hero background" />
      </div>
      <div className="home-text">
        <h1>Professional Cleaning</h1>
        <h3>Your Best Choice for Commercial Cleaning</h3>
      </div>
    </div>
  );
}

export default Home;
