import React from "react";
import { useForm, ValidationError } from "@formspree/react";

import "../styles/Contact.css";
import PhoneIcon from "@mui/icons-material/Phone";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import EmailIcon from "@mui/icons-material/Email";

function Contact() {
  const [state, handleSubmit] = useForm("xleklvww");
  if (state.succeeded) {
    return (
      <h3
        style={{
          textAlign: "center",
          fontSize: "28px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        Thanks for contact us!
      </h3>
    );
  }

  return (
    <div className="contact-container" id="contact">
      <div className="contact-title">
        <h1>Contact Us</h1>
      </div>
      <div className="form-container">
        <div className="contact-left">
          <div className="contact-form">
            <form
              method="POST"
              name="contact"
              className="form"
              action="https://formspree.io/f/xleklvww"
              onSubmit={handleSubmit}
            >
              <input type="hidden" name="form-name" value="contact" />
              <div className="form-company-name">
                <input
                  type="text"
                  placeholder="Company Name"
                  name="Company"
                  required
                />
                <ValidationError
                  prefix="Company"
                  field="company"
                  errors={state.errors}
                />
              </div>
              <div className="form-names">
                <input
                  type="text"
                  placeholder="First Name"
                  name="First Name"
                  required
                />
                <ValidationError
                  prefix="Name"
                  field="name"
                  errors={state.errors}
                />
                <input
                  type="text"
                  placeholder="Last Name"
                  name="Last Name"
                  required
                />
                <ValidationError
                  prefix="Lastname"
                  field="lastname"
                  errors={state.errors}
                />
              </div>
              <div className="form-address">
                <input
                  type="text"
                  placeholder="Address"
                  name="Address"
                  required
                />
                <ValidationError
                  prefix="Address"
                  field="address"
                  errors={state.errors}
                />
              </div>
              <div className="form-city-state-zip">
                <input type="text" placeholder="City" name="City" required />
                <ValidationError
                  prefix="City"
                  field="city"
                  errors={state.errors}
                />
                <input type="text" placeholder="State" name="State" required />
                <ValidationError
                  prefix="State"
                  field="state"
                  errors={state.errors}
                />
                <input
                  type="text"
                  placeholder="Zip Code"
                  name="Zip Code"
                  required
                />
                <ValidationError
                  prefix="ZipCode"
                  field="zipcode"
                  errors={state.errors}
                />
              </div>
              <div className="form-phone-email">
                <input
                  className="phone-input"
                  type="text"
                  placeholder="Phone"
                  name="Phone"
                  required
                />
                <ValidationError
                  prefix="Phone"
                  field="phone"
                  errors={state.errors}
                />
                <input
                  className="email-input"
                  type="text"
                  placeholder="Email"
                  name="Email"
                  required
                />
                <ValidationError
                  prefix="Email"
                  field="email"
                  errors={state.errors}
                />
              </div>
              <div className="form-service-requested">
                <input
                  type="text"
                  placeholder="Service Requested"
                  name="Service"
                  required
                />
                <ValidationError
                  prefix="Service"
                  field="service"
                  errors={state.errors}
                />
              </div>
              {/* <input type="text" placeholder="Comments" /> */}
              <button type="submit">Submit</button>
            </form>
          </div>
        </div>
        <div className="contact-right">
          <div className="contact-content-item">
            <div className="contact-item">
              <div className="contact-icons">
                <PhoneIcon />
                <p>(551)-318-9085</p>
              </div>
            </div>
            <div className="contact-item">
              <div className="contact-icons">
                <LocationOnIcon />
                <div className="contact-text">
                  <p>198 Central Avenue, Suite #2D</p>
                  <p>East Orange, New Jersey</p>
                  <p>070118</p>
                </div>
              </div>
            </div>
            <div className="contact-item">
              <div className="contact-icons">
                <EmailIcon />
                <p>info@azulbubbles.com</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;
